<template>
  <div class="middlePicture">
    <img :src="imgUrl" autofocus="" />
    <h3>发生错误了捏</h3>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",

  data() {
    return {
      imgUrl: " ",
    };
  },
  created() {
    let urlTemp = "assets/error.jpg";
    this.imgUrl = require("@/" + urlTemp);
  },
};
</script>

<style scoped>
.middlePicture {
  padding-top: 50px;
  text-align: center;
  top: 50%;
}
</style>
