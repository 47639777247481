export function timeFormatter(minute) {
  let hour = String(Math.floor(minute / 60));
  minute = String(minute % 60);
  return hour.padStart(2, "0") + ":" + minute.padStart(2, "0");
}

export function middleState(contest, currentTime = Number.MAX_VALUE, frozen = Number.MAX_VALUE) {
  const detail = contest.team_detail;
  let scores = [];
  let status_detail = [];
  for (let i = 0; i < detail.length; ++i) {
    const all_submission = detail[i].submission_detail.filter((submission) => submission.time <= currentTime);
    let solved = 0,
      penalty = 0,
      last = 0;
    let wrong_tries = 0,
      total_tries = 0;
    let statuses = [];
    for (const problem of contest.problems) {
      const submissions = all_submission.filter((submission) => submission.problem === problem);
      const passed_submissions = submissions.filter((submission) => submission.passed && submission.time < frozen);
      if (passed_submissions.length > 0) {
        const first_ac = Math.min(...passed_submissions.map((submission) => submission.time));
        const dirt = submissions.reduce(
          (count, submission) => (!submission.passed && submission.time <= first_ac ? ++count : count),
          0
        );
        ++solved;
        penalty += first_ac + dirt * 20;
        last = Math.max(last, first_ac);
        wrong_tries += dirt;
        total_tries += dirt + 1;
        statuses.push({ type: "accepted", dirt_before: dirt, dirt_after: 0, time: first_ac });
      } else {
        const dirt_before = submissions.reduce((count, submission) => (submission.time < frozen ? ++count : count), 0);
        const dirt_after = submissions.length - dirt_before;
        statuses.push({ type: "rejected", dirt_before, dirt_after, time: -1 });
      }
    }
    scores.push([-solved, penalty, last, i, wrong_tries, total_tries]);
    status_detail.push(statuses);
  }
  for (let i = 0; i < contest.problems.length; ++i) {
    const ac_times = status_detail.flatMap((statuses) => (statuses[i].type === "accepted" ? [statuses[i].time] : []));
    if (ac_times.length > 0) {
      const first_blood = Math.min(...ac_times);
      status_detail.forEach((statuses) => {
        if (statuses[i].time === first_blood) {
          statuses[i].fb = true;
        }
      });
    }
  }
  scores = scores.sort((a, b) => {
    for (let i = 0; i < 3; ++i) {
      if (a[i] !== b[i]) {
        return a[i] - b[i];
      }
    }
    return 0;
  });

  let rank = 0;
  let real_rank = 0;
  let background = 0;
  let prev_solved = -1;
  let row_count = 0;
  let contest_detail = [];
  for (let k = 0; k < detail.length; ++k) {
    let i = scores[k][3];
    let team = detail[i];
    let status = status_detail[i];
    let star = team.star;
    if (!star) {
      rank++;
    }
    if (k === 0 || JSON.stringify(scores[k].slice(0, 3)) !== JSON.stringify(scores[k - 1].slice(0, 3))) {
      real_rank = k + 1;
    }

    let solved = -scores[k][0];
    let penalty = scores[k][1];
    if (solved !== prev_solved) {
      background ^= 1;
      row_count = 0;
    }
    prev_solved = solved;
    ++row_count;
    let background_color;
    if (row_count % 2 === 1) {
      background_color = background ? "#f8f8f8" : "#e8e8ff";
    } else {
      background_color = background ? "#ffffff" : "#f0f0ff";
    }

    const wrong_tries = scores[k][4];
    const total_tries = scores[k][5];
    let team_status = {
      team_id: i,
      name: team.name,
      member: team.member,
      rank: star ? "*" : rank,
      real_rank,
      background_color,
      solved,
      penalty,
      status,
      wrong_tries,
      total_tries,
    };
    contest_detail.push(team_status);
  }
  return contest_detail;
}
