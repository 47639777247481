const API_URL = process.env.VUE_APP_API_URL;
const YEAR = process.env.VUE_APP_YEAR;
const BAN_TRAINING_CONTEST = +process.env["VUE_APP_BAN_TRAINING_CONTEST_" + YEAR];
const CODEFORCES_BEGIN = process.env["VUE_APP_CODEFORCES_BEGIN_" + YEAR];
const CODEFORCES_END = process.env["VUE_APP_CODEFORCES_END_" + YEAR];
const CODEFORCES_LEAST_COMPETE = process.env["VUE_APP_CODEFORCES_LEAST_COMPETE_" + YEAR];

export default {
  API_URL,
  YEAR,
  BAN_TRAINING_CONTEST,
  CODEFORCES_BEGIN,
  CODEFORCES_END,
  CODEFORCES_LEAST_COMPETE,
};
