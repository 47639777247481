import { render, staticRenderFns } from "./TotalRating.vue?vue&type=template&id=dff6fffa&scoped=true"
import script from "./TotalRating.vue?vue&type=script&lang=js"
export * from "./TotalRating.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff6fffa",
  null
  
)

export default component.exports