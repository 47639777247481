import { get } from "./request";

export async function getTrainingScoreRecord(params) {
  return await get("/training/score_record/get_score_record/", params);
}

export async function getTrainingContestUpsolving(params) {
  return await get("/training/contest/get_upsolving/", params);
}

export async function getTrainingYears(params) {
  return await get("/training/contest/years/", params);
}

export async function getTrainingContests(params) {
  return await get("/training/contest/get_contest/", params);
}

export async function getTrainingContestDetail(params) {
  return await get("/training/contest/get_contest_detail/", params);
}

export async function getTrainingContestUpsolvingProblems(contest, params) {
  return await get(`/training/contest/${contest}/upsolving_problems/`, params);
}
