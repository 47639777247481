import axios from "axios";
import constant from "@/components/Constant";

async function request(option) {
  option.headers = {
    "Content-Type": "application/json",
  };
  option.baseURL = constant.API_URL;
  return await axios(option).catch((error) => {
    console.log(error);
    // copied from https://axios-http.com/docs/handling_errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error:", error.message);
    }
    console.log(error.config);
  });
}

export async function get(url, params) {
  return await request({
    url,
    method: "get",
    params,
  });
}

export async function post(url, data) {
  return await request({
    url,
    method: "post",
    data,
  });
}
