import { get } from "./request";

export async function honorAll(params) {
  return await get("/honor/", params);
}

export async function honorCount(params) {
  return await get("/honor/count/", params);
}

export async function honorTrophy(params) {
  return await get("/honor/trophy/", params);
}
