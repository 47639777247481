import { get } from "./request";

export async function statisticRating(params) {
  return await get("/statistic/rating/", params);
}

export async function statisticCodeforcesRatingDetail(params) {
  return await get("/statistic/codeforces/rating_detail/", params);
}

export async function statisticCodeforcesRating(params) {
  return await get("/statistic/codeforces/rating/", params);
}

export async function statisticUpsolvingScore(params) {
  return await get("/statistic/upsolving/score/", params);
}
