<template>
  <footer class="footer">
    <p style="background-color: #f5f5f5; margin: 0; padding: 0.5em 0; height: 4em" class="text-muted">
      CVBB ACM/ICPC 集训队 2005-2023
      <br />
      <a class="text-muted" href="http://beian.miit.gov.cn/" target="_blank">京ICP备20018844号-3</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.text-muted {
  color: #777;
  text-align: center;
}
</style>
