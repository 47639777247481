<template>
  <b-container id="training-container" class="pt-5">
    <b-row no-gutters>
      <b-col cols="12" md="2">
        <div style="display: flex">
          <b-list-group>
            <b-list-group-item
              v-for="year of years"
              :key="year"
              button
              :active="active_year === year"
              class="list-group-item"
              style="width: 3.5rem"
              @click="getContests(year)"
            >
              {{ year }}
            </b-list-group-item>
          </b-list-group>
          <b-list-group>
            <b-list-group-item
              v-for="contest_item of contests"
              :key="contest_item.id"
              v-b-tooltip.hover.right="contest_item.name"
              button
              :active="active_contest_id === contest_item.id"
              class="list-group-item"
              style="width: 4rem"
              @click="getContest(contest_item)"
            >
              {{ contest_item.start_time.substring(5, 10) }}
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col cols="12" md="10">
        <ScoreBoard :contest="contest" />
        <div id="training-chart-container">
          <EChartWrapper
            v-if="option"
            :key="training_chart_key"
            index="training-chart"
            :option="option"
            :click-event="click_event"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// FIXME: This part of code seems to be too shitty. Should be rewritten in the future.
import { getTrainingYears, getTrainingContests, getTrainingContestDetail } from "@/apis/training";
import { timeFormatter, middleState } from "@/pages/Training/Util";
import EChartWrapper from "@/components/EChartWrapper";
import ScoreBoard from "@/pages/Training/ScoreBoard";

export default {
  name: "TeamTraining",

  components: { ScoreBoard, EChartWrapper },

  data() {
    return {
      years: [],
      contests: [],
      contest: undefined,
      option: undefined,
      click_event: undefined,
      training_chart_key: undefined,
      active_year: undefined,
      active_contest_id: undefined,
    };
  },

  mounted() {
    this.getYears();
  },

  methods: {
    async getYears() {
      let response = await getTrainingYears();
      this.years = response.data.map((year) => +year).reverse();
      this.getContests(this.years[0]);
    },

    async getContests(year) {
      let response = await getTrainingContests({
        year: year,
      });
      this.active_year = year;
      this.contests = response.data;
      this.getContest(this.contests[0]);
    },

    async getContest(contest) {
      const date = contest.start_time.substring(0, 10);
      let response = await getTrainingContestDetail({
        date: date,
      });
      this.active_contest_id = contest.id;
      this.contest = response.data[0];
      this.renderTable();
      this.renderChart();
    },

    renderTable(currentTime = Number.MAX_VALUE, frozen = Number.MAX_VALUE) {
      this.contest = {
        ...this.contest,
        current_submission: middleState(this.contest, currentTime, frozen),
      };
    },

    tooltipFormatter(teams) {
      let src = "";
      const time = teams[0].axisValue;
      const rank = [...Array(teams.length).keys()].sort((a, b) => {
        return teams[a].value[1] - teams[b].value[1];
      });
      let statuses = this.contest.team_detail;
      src += "<div><b>" + timeFormatter(time) + "</b></div>";
      src += "<div style='text-align: left;'>";
      for (let i = 0; i < teams.length; ++i) {
        src += "<div>";
        src += "<span>" + teams[rank[i]].marker + teams[rank[i]].seriesName + "</span>";
        let pass = "";
        for (const submission of statuses[rank[i]].submission_detail) {
          if (submission.passed && submission.time === time) {
            pass += submission.problem;
          }
        }
        src += "<span style='float: right; margin-left: 20px;'><b>" + pass + "</b></span>";
        src += "</div>";
      }
      src += "</div>";
      return src;
    },

    renderChart() {
      let option = {
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          formatter: this.tooltipFormatter,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        grid: {
          left: "5%",
          right: "18%",
        },
        xAxis: {
          name: "Time",
          nameLocation: "middle",
          type: "value",
          minInterval: 1,
          interval: 60,
          min: 0,
          max: this.contest.duration || 300,
        },
        yAxis: {
          name: "Rank",
          nameLocation: "middle",
          nameGap: 20,
          type: "value",
          inverse: true,
          min: 0,
          max: this.contest.team_detail.length + 1,
        },
      };

      let statuses = this.contest.team_detail;
      const nameData = statuses.map((team) => team.name.slice(0, 9) + (team.name.length > 9 ? "..." : ""));
      option.legend = {
        orient: "vertical",
        right: 0,
        top: 60,
        bottom: 60,
        data: nameData,
      };

      let problem_num = this.contest.problems.length;
      let pass_time = [0, this.contest.duration];
      if (this.contest.duration >= 150) {
        pass_time.push(this.contest.duration - 60);
      }
      for (let status of statuses) {
        status.submission_detail.forEach(({ time }) => {
          if (time >= 0) {
            pass_time.push(time);
          }
        });
      }
      pass_time = Array.from(new Set(pass_time)).sort(function (a, b) {
        return a - b;
      });

      option.series = [];
      let points = [];
      for (let i = 0; i < statuses.length; ++i) {
        let status = {};
        status.name = nameData[i];
        status.type = "line";
        status.data = [];
        status.markPoint = {
          symbol: "pin",
          symbolSize: 25,
          data: [],
        };
        option.series.push(status);
        points.push([]);
      }
      option.series.push({
        type: "line",
        markArea: {
          itemStyle: {
            color: "rgba(237, 237, 237, 0.6)",
          },
          data: [
            [
              {
                name: "Last hour",
                xAxis: this.contest.duration - 60,
              },
              {
                xAxis: this.contest.duration,
              },
            ],
          ],
        },
      });

      for (let k = 0; k < pass_time.length; ++k) {
        let T = pass_time[k];
        const contest_detail = middleState(this.contest, T);
        for (let id = 0; id < contest_detail.length; ++id) {
          const status = contest_detail[id];
          const { team_id, real_rank } = status;
          let pass = false;
          for (let j = 0; j < problem_num; ++j) {
            let t = status.status[j].time;
            if (t === pass_time[k]) {
              option.series[team_id].markPoint.data.push({
                name: this.contest.problems[j] + " passed",
                value: this.contest.problems[j],
                coord: [T, real_rank],
              });
              pass = true;
            }
          }
          points[team_id].push([T, real_rank, pass]);
        }
      }
      for (let i = 0; i < statuses.length; ++i) {
        for (let j = 0; j < points[i].length; ++j) {
          option.series[i].data.push({
            symbol: "none",
            value: [points[i][j][0], points[i][j][1]],
          });
        }
      }
      this.click_event = (params) => {
        if (params.componentType === "markPoint") {
          this.renderTable(params.data.coord[0]);
        }
        if (params.componentType === "markArea") {
          this.renderTable();
        }
      };
      this.option = option;
      this.training_chart_key = Date.now();
    },
  },
};
</script>

<style scoped>
/deep/ #training-chart {
  height: 600px;
  width: 900px;
}

#training-chart-container {
  overflow: auto;
}

#training-container {
  text-align: center;
}

.list-group-item {
  font-size: 0.5rem;
  font-weight: 450;
  padding: 0.5rem 0;
  height: 2rem;
}

.list-group-item:not(.active):hover {
  background-color: #f5f5f5;
}
</style>
