<template>
  <b-container class="pt-3">
    <h2>积分榜</h2>
    <BarEChart :chart-data="chartData1" index="training-chart-1" :team-name-length="6" />
    <h2>积分排名</h2>
    <LineEChart :chart-data="chartData2" index="training-chart-2" :team-name-length="9" />
    <h2>训练排名</h2>
    <LineEChart :chart-data="chartData3" index="training-chart-3" :team-name-length="9" />
  </b-container>
</template>

<script>
import { getTeam } from "@/apis/team";
import { getTrainingScoreRecord } from "@/apis/training";
import BarEChart from "@/components/BarEChart";
import LineEChart from "@/components/LineEChart";
import constant from "@/components/Constant";

export default {
  name: "TrainingRating",

  components: { BarEChart, LineEChart },

  data() {
    return {
      chartData1: [],
      chartData2: {},
      chartData3: {},
    };
  },

  mounted() {
    this.getCharts();
  },

  methods: {
    async getCharts() {
      let response = await getTeam({
        year: constant.YEAR,
      });
      response = await getTrainingScoreRecord({
        team: response.data.map((team) => team.id),
        year: constant.YEAR,
      });
      let teams = new Set();
      for (let item of response.data) {
        for (let team of Object.keys(item.score_list)) {
          teams.add(team);
        }
      }
      teams = Array.from(teams);
      let chartData1 = [];
      let chartData2 = {
        x_axis_data: response.data.map((board) => board.date.substring(5)),
        teams,
        data: [],
      };
      let chartData3 = {
        x_axis_data: response.data.map((board) => board.date.substring(5)),
        teams,
        data: [],
      };
      const scoreLists = response.data.map((board) => board.score_list);
      const ban = constant.BAN_TRAINING_CONTEST;
      let points = [];
      for (let teamId = 0; teamId < teams.length; ++teamId) {
        let l = [];
        let point = [];
        let count = 0;
        let last = 0;
        let team = teams[teamId];
        for (let training of scoreLists) {
          count++;
          let score = training[team] || 0;
          l.push(score);
          l = l.sort((a, b) => a - b);
          let less;
          if (ban === 0) {
            less = 0;
          } else {
            let block = scoreLists.length / ban;
            less = Math.min(Math.floor(count / block), ban);
          }
          let sum = 0;
          for (let i = less; i < l.length; ++i) {
            sum += l[i];
          }
          point.push(sum);
          last = sum;
        }
        points.push(point);
        chartData1.push([team, last]);
        chartData2.data.push([]);
        chartData3.data.push([]);
      }
      for (let i = 0; i < scoreLists.length; ++i) {
        for (let j = 0; j < teams.length; ++j) {
          let rank = 1;
          for (let k = 0; k < teams.length; ++k) {
            if (points[k][i] > points[j][i]) {
              ++rank;
            }
          }
          chartData2.data[j].push(rank);
          rank = 1;
          for (let k = 0; k < teams.length; ++k) {
            if ((scoreLists[i][teams[k]] || 0) > (scoreLists[i][teams[j]] || 0)) {
              ++rank;
            }
          }
          chartData3.data[j].push(rank);
        }
      }
      this.chartData1 = chartData1;
      this.chartData2 = chartData2;
      this.chartData3 = chartData3;
    },
  },
};
</script>

<style scoped></style>
