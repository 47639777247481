<template>
  <div>
    <NavBar />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
import NavBar from "@/components/NavBar";

export default {
  name: "BaseTemplate",
  components: { FooterComponent, NavBar },
};
</script>

<style scoped></style>
