<template>
  <b-navbar toggleable="lg" type="light" variant="light">
    <b-container>
      <b-navbar-brand id="navbar-brand" to="/">CVBBICPC</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/home" active-class="active">Home</b-nav-item>
          <b-nav-item to="/honor" active-class="active">Honor</b-nav-item>
          <b-nav-item-dropdown text="Rating">
            <b-dropdown-item to="/rating/total">总积分</b-dropdown-item>
            <b-dropdown-item to="/rating/training">训练积分</b-dropdown-item>
            <b-dropdown-item to="/rating/codeforces">Codeforces</b-dropdown-item>
            <b-dropdown-item to="/rating/upsolving">补题</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/training" active-class="active">Training</b-nav-item>
          <b-nav-item href="https://wiki.cvbbacm.com/">Wiki</b-nav-item>
          <b-nav-item-dropdown text="BCPC">
            <b-dropdown-item href="https://accoding.buaa.edu.cn/acm/2022">BCPC2022</b-dropdown-item>
            <b-dropdown-item href="https://accoding.buaa.edu.cn/acm/2021">BCPC2021</b-dropdown-item>
            <b-dropdown-item href="https://accoding.buaa.edu.cn/acm/2020">BCPC2020</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped></style>
