<template>
  <div class="mx-auto">
    <b-container id="training-container" class="pt-5">
      <b-row no-gutters>
        <b-col cols="12" md="2">
          <b-navbar class="sticky-top">
            <b-nav vertical>
              <b-nav-item-dropdown
                v-for="award of awards"
                :key="award.year"
                :text="award.year + '~' + (award.year + 1)"
                :href="'#' + award.year"
                active
                dropright
              >
                <b-dropdown-item v-if="award.wf.length > 0" :href="'#item-' + award.year + '-wf'">
                  ICPC World Finals
                </b-dropdown-item>
                <b-dropdown-item v-if="award.icpc.length > 0" :href="'#item-' + award.year + '-icpc'">
                  ICPC Regional
                </b-dropdown-item>
                <b-dropdown-item v-if="award.ccpc.length > 0" :href="'#item-' + award.year + '-ccpc'">
                  CCPC
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-nav>
          </b-navbar>
        </b-col>
        <b-col cols="12" md="10">
          <div v-for="award of awards" :id="award.year" :key="award.year">
            <h2>{{ award.year }}~{{ award.year + 1 }}</h2>
            <div v-if="award.wf.length > 0">
              <h3 :id="'item-' + award.year + '-wf'">ICPC World Finals</h3>
              <div v-for="region of award.wf" :key="region.info.region">
                <h4 :title="region.info.date">{{ region.info.region }}</h4>
                <AwardTable :awards="region.honors" />
              </div>
            </div>
            <div v-if="award.icpc.length > 0">
              <h3 :id="'item-' + award.year + '-icpc'">ICPC Regional</h3>
              <div v-for="region of award.icpc" :key="region.info.region">
                <h4 :title="region.info.date">{{ region.info.region }}</h4>
                <AwardTable :awards="region.honors" />
              </div>
            </div>
            <div v-if="award.ccpc.length > 0">
              <h3 :id="'item-' + award.year + '-ccpc'">CCPC</h3>
              <div v-for="region of award.ccpc" :key="region.info.region">
                <h4 :title="region.info.date">{{ region.info.region }}</h4>
                <AwardTable :awards="region.honors" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AwardTable from "@/components/AwardTable";
import { honorAll } from "@/apis/honor";

export default {
  name: "XCPCHonor",

  components: { AwardTable },

  data() {
    return {
      awards: [],
    };
  },

  mounted() {
    this.getAwards();
  },

  methods: {
    async getAwards() {
      const response = await honorAll();
      let years = Object.keys(response.data);
      years = years.sort().reverse();
      for (let year of years) {
        const detail = response.data[year];
        this.awards.push({
          year: +year,
          wf: detail.filter((contest) => contest.info.type === "World-Finals"),
          icpc: detail.filter((contest) => contest.info.type === "ICPC"),
          ccpc: detail.filter((contest) => contest.info.type === "CCPC"),
        });
      }
    },
  },
};
</script>

<style scoped></style>
