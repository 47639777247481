<template>
  <b-container class="pt-3">
    <h2>总积分榜</h2>
    <BarEChart :chart-data="total_rating_data" index="total-rating-chart" :team-name-length="6" />
  </b-container>
</template>

<script>
import BarEChart from "@/components/BarEChart";
import { statisticRating } from "@/apis/statistic";
import { getTeam } from "@/apis/team";
import constant from "@/components/Constant";

export default {
  name: "TotalRating",

  components: { BarEChart },

  data() {
    return {
      total_rating_data: [],
    };
  },

  mounted() {
    this.getTotalData();
  },

  methods: {
    async getTotalData() {
      let response = await getTeam({
        year: constant.YEAR,
      });
      response = await statisticRating({
        team: response.data.map((team) => team.id),
        year: constant.YEAR,
      });
      this.total_rating_data = Object.entries(response.data);
    },
  },
};
</script>

<style scoped></style>
