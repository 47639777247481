<template>
  <b-container class="pt-3">
    <h2>CVBB ACM/ICPC 集训队</h2>
    <h5>Summary</h5>
    <EChartWrapper v-if="option" index="chart" :option="option" />
    <hr />
    <h5>Honor</h5>
    <AwardTable :awards="trophies" has-date-contest>
      <colgroup>
        <col style="width: 7rem" />
        <col style="width: 20rem" />
        <col style="width: 10rem" />
      </colgroup>
      <colgroup>
        <col style="width: 5rem" />
        <col style="width: 5rem" />
        <col style="width: 5rem" />
      </colgroup>
      <colgroup>
        <col style="width: 4rem" />
      </colgroup>
    </AwardTable>
    <hr />
    <h5>2021 集训日程</h5>
    <div id="calendar-container">
      <iframe
        id="calendar"
        src="https://calendar.google.com/calendar/embed?showNav=0&amp;showPrint=0&amp;mode=WEEK&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=nj38f7ehhaiugeu0igco2umjn8%40group.calendar.google.com&amp;color=%23182C57&amp;src=iu1iul1u3n8ic3s78f4df15u4o%40group.calendar.google.com&amp;color=%23333333&amp;ctz=Asia%2FShanghai"
        style="border-width: 0"
        frameborder="0"
        scrolling="no"
      />
    </div>
  </b-container>
</template>

<script>
import AwardTable from "@/components/AwardTable";
import EChartWrapper from "@/components/EChartWrapper";
import { honorCount, honorTrophy } from "@/apis/honor";
import "@/assets/style.css";

export default {
  name: "HomePage",

  components: { EChartWrapper, AwardTable },

  data() {
    return {
      trophies: [],
      option: undefined,
    };
  },

  mounted() {
    this.getMedals();
    this.getTrophies();
  },

  methods: {
    async getMedals() {
      const response = await honorCount({});
      const years = Object.keys(response.data)
        .map((year) => +year)
        .sort();

      let option = {
        toolbox: {
          show: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          right: "10%",
        },
        xAxis: {
          data: years,
        },
        yAxis: {
          type: "value",
        },
      };

      let color_map = new Map([
        ["金奖", "#ffd965"],
        ["银奖", "#d0cece"],
        ["铜奖", "#f4b083"],
      ]);
      let awards = [...color_map.keys()];
      option.legend = {
        data: awards,
      };

      option.series = [];
      for (let award of awards) {
        option.series.push({
          name: award,
          type: "line",
          data: [],
          color: color_map.get(award),
        });
      }
      for (let year of years) {
        for (let i = 0; i < awards.length; ++i) {
          option.series[i].data.push(response.data[year][awards[i]] || 0);
        }
      }
      this.option = option;
    },

    async getTrophies() {
      const response = await honorTrophy({});
      this.trophies = response.data;
      for (let trophy of this.trophies) {
        trophy.date = trophy.date.split("-").join(".");
      }
    },
  },
};
</script>

<style scoped>
h5 {
  font-weight: 300;
}

@media (min-width: 768px) {
  /deep/ #chart {
    height: 30em;
  }

  #calendar {
    height: 60em;
    width: 80em;
  }
}

@media (max-width: 767.98px) {
  /deep/ #chart {
    height: 18em;
  }

  #calendar {
    height: 40em;
    width: 40em;
  }

  #calendar-container {
    overflow: auto;
  }
}
</style>
