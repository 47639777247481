<template>
  <div style="overflow: auto">
    <EChartWrapper v-if="option" :index="index" :option="option" style="height: 450px; width: 1100px" />
  </div>
</template>

<script>
import EChartWrapper from "@/components/EChartWrapper";

export default {
  name: "BarEChart",

  components: { EChartWrapper },

  props: {
    chartData: {
      type: Array,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    teamNameLength: {
      type: Number,
      default: 6,
    },
  },

  data() {
    return {
      option: undefined,
    };
  },

  watch: {
    chartData() {
      this.getChart();
    },
  },

  methods: {
    getChart() {
      // eslint-disable-next-line no-unused-vars
      const data = this.chartData.slice().sort(([_name1, score1], [_name2, score2]) => score2 - score1);
      const max_len = this.teamNameLength;
      // eslint-disable-next-line no-unused-vars
      const nameData = data.map(([name, _score]) => name.slice(0, max_len) + (name.length > max_len ? "..." : ""));
      // eslint-disable-next-line no-unused-vars
      const scoreData = data.map(([_name, score]) => score);
      let option = {
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#fff",
          },
        },
        toolbox: {
          show: false,
        },
        yAxis: [
          {
            inverse: true,
            type: "category",
            data: nameData,
          },
        ],
        xAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            type: "bar",
            label: {
              show: true,
            },
            itemStyle: {
              color: function (params) {
                let colorList = [
                  "#c23531",
                  "#2f4554",
                  "#61a0a8",
                  "#d48265",
                  "#91c7ae",
                  "#749f83",
                  "#ca8622",
                  "#bda29a",
                  "#6e7074",
                  "#546570",
                  "#c4ccd3",
                ];
                return colorList[params.dataIndex % colorList.length];
              },
            },
            data: scoreData,
          },
        ],
      };
      this.option = option;
    },
  },
};
</script>

<style scoped></style>
