<template>
  <div v-if="contest && contest.current_submission">
    <h2>{{ contest.name }}</h2>
    <p>{{ contest.start_time.substring(0, 10) }}</p>
    <b-table-simple responsive bordered caption-top class="scoreboard">
      <caption>
        Standings
      </caption>
      <b-thead>
        <b-tr>
          <b-th>#</b-th><b-th>Who</b-th><b-th>=</b-th><b-th>Penalty</b-th>
          <b-th v-for="problem of contest.problems" :key="problem">{{ problem }}</b-th>
          <b-th>Dirt</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <BoardRow v-for="status of contest.current_submission" :key="status.name" :status="status" />
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import BoardRow from "@/pages/Training/BoardRow";

export default {
  name: "ScoreBoard",

  components: { BoardRow },

  props: {
    contest: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
