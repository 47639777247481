<template>
  <b-tr :style="{ backgroundColor: status.background_color }">
    <b-td>{{ status.rank }}</b-td>
    <b-td :title="status.member">{{ status.name }}</b-td>
    <b-td>{{ status.solved }}</b-td>
    <b-td>{{ status.penalty }}</b-td>
    <b-td v-for="(detail, index) of status.status" :key="index" :class="{ fb: detail.fb }">
      <div :class="detail.type">
        <span v-if="detail.type === 'accepted'">+{{ detail.dirt_before || "" }}</span>
        <div v-else-if="detail.dirt_after > 0" class="unknown">
          <span v-if="detail.dirt_before > 0"
            >{{ -detail.dirt_before || "" }} <br />
            ? {{ detail.dirt_after }}</span
          >
          <span v-else>? {{ detail.dirt_after }}</span>
        </div>
        <span v-else>{{ -detail.dirt_before || "" }}</span>
      </div>
      <span v-if="detail.type === 'accepted'">{{ formatter(detail.time) }}</span>
    </b-td>
    <b-td
      ><span
        ><b>{{ dirt_rate(status) }}</b></span
      ><br />{{ status.wrong_tries }}/{{ status.total_tries }}</b-td
    >
  </b-tr>
</template>

<script>
import { timeFormatter } from "@/pages/Training/Util";

export default {
  name: "BoardRow",

  props: {
    status: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatter: timeFormatter,

    dirt_rate(status) {
      let dirt_rate = status.total_tries > 0 ? Math.round((status.wrong_tries * 100) / status.total_tries) : 0;
      return dirt_rate + "%";
    },
  },
};
</script>

<style scoped></style>
