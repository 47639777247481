<template>
  <div :id="index" />
</template>

<script>
const echarts = require("echarts");
import "echarts/theme/royal";

export default {
  name: "EChartWrapper",

  props: {
    index: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    clickEvent: {
      type: Function,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      eChart: undefined,
    };
  },

  mounted() {
    this.eChart = echarts.init(document.getElementById(this.index), "royal");
    this.eChart.setOption(this.option);
    if (this.clickEvent) {
      this.eChart.on("click", this.clickEvent);
    }
    window.addEventListener("resize", this.eChart.resize);
  },

  beforeDestroy() {
    if (this.eChart) {
      window.removeEventListener("resize", this.eChart.resize);
      this.eChart.dispose();
    }
  },
};
</script>

<style scoped></style>
