<template>
  <b-table-simple responsive fixed bordered striped>
    <slot />
    <b-thead>
      <b-tr>
        <template v-if="hasDateContest">
          <b-th>日期</b-th>
          <b-th>赛区</b-th>
        </template>
        <b-th>队伍</b-th>
        <b-th colspan="3">成员</b-th>
        <b-th>奖项</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="award of awards" :key="award.id">
        <template v-if="hasDateContest">
          <b-td>{{ award.date }}</b-td>
          <b-td>{{ award.contest }}</b-td>
        </template>
        <b-td>{{ award.name || award.english_name }}</b-td>
        <b-td>{{ award.member1 }}</b-td>
        <b-td>{{ award.member2 }}</b-td>
        <b-td>{{ award.member3 }}</b-td>
        <b-td
          ><b>{{ award.award }}</b></b-td
        >
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  name: "AwardTable",

  props: {
    awards: {
      type: Array,
      required: true,
    },
    hasDateContest: Boolean,
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>
