<template>
  <div style="overflow: auto">
    <EChartWrapper v-if="option" :index="index" :option="option" style="height: 480px; width: 1100px" />
  </div>
</template>

<script>
import EChartWrapper from "@/components/EChartWrapper";

export default {
  name: "LineEChart",

  components: { EChartWrapper },

  props: {
    chartData: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    teamNameLength: {
      type: Number,
      default: 6,
    },
  },

  data() {
    return {
      option: undefined,
    };
  },

  watch: {
    chartData() {
      this.getChart();
    },
  },

  methods: {
    getChart() {
      let option = {
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        grid: {
          right: "15%",
        },
        xAxis: {
          data: this.chartData.x_axis_data,
        },
        yAxis: {
          name: "Rank",
          nameLocation: "middle",
          nameGap: 25,
          type: "value",
          inverse: true,
        },
      };
      const max_len = this.teamNameLength;
      const teams = this.chartData.teams;
      const nameData = teams.map((name) => name.slice(0, max_len) + (name.length > max_len ? "..." : ""));
      option.legend = {
        orient: "vertical",
        right: 0,
        top: 10,
        bottom: 10,
        data: nameData,
      };
      option.series = [];
      for (let i = 0; i < nameData.length; ++i) {
        let status = {
          name: nameData[i],
          type: "line",
          data: this.chartData.data[i],
        };
        option.series.push(status);
      }
      this.option = option;
    },
  },
};
</script>

<style scoped></style>
