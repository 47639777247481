import Vue from "vue";
import VueRouter from "vue-router";

import BaseTemplate from "@/pages/BaseTemplate";
import HomePage from "@/pages/HomePage";
import XCPCHonor from "@/pages/XCPCHonor";
import TotalRating from "@/pages/Rating/TotalRating";
import TeamTraining from "@/pages/Training/TeamTraining.vue";
import TrainingRating from "@/pages/Rating/TrainingRating";
import CodeforcesRating from "@/pages/Rating/CodeforcesRating";
import UpsolvingRating from "@/pages/Rating/UpsolvingRating";
import ErrorPage from "@/pages/ErrorPage";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/dummy-base",
      name: "base",
      component: BaseTemplate,
      redirect: "/home",
      children: [
        {
          path: "/home",
          name: "home",
          component: HomePage,
        },
        {
          path: "/honor",
          name: "honor",
          component: XCPCHonor,
        },
        {
          path: "/rating/total",
          name: "ratingTotal",
          component: TotalRating,
        },
        {
          path: "/rating/training",
          name: "ratingTraining",
          component: TrainingRating,
        },
        {
          path: "/rating/codeforces",
          name: "ratingCodeforces",
          component: CodeforcesRating,
        },
        {
          path: "/rating/upsolving",
          name: "ratingUpsolving",
          component: UpsolvingRating,
        },
        {
          path: "/training",
          name: "training",
          component: TeamTraining,
        },
        {
          path: "/error",
          name: "Error",
          component: ErrorPage,
        },
      ],
    },
    {
      path: "/",
      name: "root",
      redirect: "/home",
    },
    {
      path: "*",
      name: "Error",
      redirect: "/error",
    },
  ],
});

export default router;
