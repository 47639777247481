<template>
  <b-container class="pt-3">
    <h2>Upsolving Board</h2>
    <!-- TODO Implement sticky header and column, Bootstrap's default one did not work very well -->
    <b-table-simple responsive bordered caption-top class="scoreboard">
      <caption>
        Upsolving
      </caption>
      <b-thead>
        <b-tr>
          <b-th rowspan="2">Who</b-th>
          <b-th rowspan="2">=</b-th>
          <b-th v-for="info of contest_info" :key="info.name" :colspan="info.upsolving_problems.length">{{
            info.name
          }}</b-th>
        </b-tr>
        <b-tr>
          <template v-for="info of contest_info">
            <b-th v-for="problem of info.upsolving_problems" :key="info.name + '-' + problem">{{ problem }}</b-th>
          </template>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="detail in details" :key="detail.name">
          <b-td :title="detail.member">{{ detail.name }}</b-td>
          <b-td>{{ detail.valid_count }}</b-td>
          <b-td v-for="(solved, index) in detail.valid_solved" :key="index">{{ solved }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <h2>Upsolving Ranklist</h2>
    <BarEChart :chart-data="score" index="upsolving-score" :team-name-length="6" />
  </b-container>
</template>

<script>
import { getTrainingContestUpsolving, getTrainingContests } from "@/apis/training";
import { statisticUpsolvingScore } from "@/apis/statistic";
import { getTeam } from "@/apis/team";
import constant from "@/components/Constant";
import BarEChart from "@/components/BarEChart";
import { getTrainingContestUpsolvingProblems } from "@/apis/training";

export default {
  name: "UpsolvingRating",

  components: { BarEChart },

  data() {
    return {
      contest_info: [],
      details: [],
      score: [],
    };
  },

  async mounted() {
    let response = await getTeam({
      year: constant.YEAR,
    });
    let teams = response.data.map((team) => team.id);
    this.getUpsolvingTable(teams);
    this.getUpsolvingScore(teams);
  },

  methods: {
    async getUpsolvingTable(teams) {
      let response = await getTrainingContestUpsolving({
        team: teams,
        year: constant.YEAR,
      });
      const contest_id = await getTrainingContests({
        year: constant.YEAR,
      });
      for (let i = 0; i < response.data.length; ++i) {
        response.data[i].id = contest_id.data[i].id;
        const upsolving_problems = await getTrainingContestUpsolvingProblems(contest_id.data[i].id);
        response.data[i].upsolving_problems = upsolving_problems.data.map((problem) => problem.index);
      }
      this.contest_info = response.data.map((contest) => {
        return {
          name: contest.name,
          problems: contest.problems,
          upsolving_problems: contest.upsolving_problems,
        };
      });
      let details = response.data[0].team_detail.map((info) => {
        return {
          id: info.id,
          name: info.name,
          member: info.member,
          solved: [],
          valid_solved: [],
          count: 0,
          valid_count: 0,
        };
      });
      for (let contest of response.data) {
        for (let i = 0; i < teams.length; ++i) {
          const teamInfo = contest.team_detail[i];
          teamInfo.upsolving_detail.forEach((solved, index) => {
            details[i].solved.push(solved ? "\u2705" : "");
            details[i].count += solved;
            if (contest.upsolving_problems.includes(String.fromCharCode(index + "A".charCodeAt()))) {
              details[i].valid_solved.push(solved ? "\u2705" : "");
              details[i].valid_count += solved;
            }
          });
        }
      }
      details.sort((a, b) => b.valid_count - a.valid_count);
      this.details = details;
    },

    async getUpsolvingScore(teams) {
      let response = await statisticUpsolvingScore({
        team: teams,
      });
      this.score = Object.entries(response.data);
    },
  },
};
</script>

<style scoped></style>
