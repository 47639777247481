<template>
  <b-container class="pt-3">
    <h2>Codeforces Rating</h2>
    <b-table-simple
      striped
      responsive
      fixed
      bordered
      no-border-collapse
      sticky-header="500px"
      style="text-align: center"
    >
      <colgroup>
        <col style="width: 16rem" />
      </colgroup>
      <colgroup>
        <col style="width: 12rem" />
        <col style="width: 5rem" />
        <col style="width: 12rem" />
        <col style="width: 5rem" />
        <col style="width: 12rem" />
        <col style="width: 5rem" />
      </colgroup>
      <b-thead>
        <b-tr>
          <b-th>队伍</b-th>
          <b-th colspan="6">成员</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="rating of ratings" :key="rating[0]">
          <b-td>{{ rating[0] }}</b-td>
          <template v-for="rating_item in rating[1]">
            <b-td :key="rating_item[0] + '-user'">
              <a
                :href="'https://codeforces.com/profile/' + rating_item[0]"
                :class="rank2color(rating_item[1])"
                class="rated-user"
              >
                {{ rating_item[0] }}
              </a>
            </b-td>
            <b-td :key="rating_item[0] + '-rating'" :class="rank2color(rating_item[1])" class="rated-user">
              {{ rating_item[2] }}
            </b-td>
          </template>
          <b-td v-for="i in 2 * (3 - rating[1].length)" :key="i" />
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <h2>总积分榜</h2>
    <BarEChart :chart-data="chart_ratings" index="codeforces-rating" :team-name-length="6" />
  </b-container>
</template>

<script>
import { getTeam } from "@/apis/team";
import { statisticCodeforcesRatingDetail, statisticCodeforcesRating } from "@/apis/statistic";
import constant from "@/components/Constant";
import BarEChart from "@/components/BarEChart";

export default {
  name: "CodeforcesRating",

  components: { BarEChart },

  data() {
    return {
      ratings: [],
      chart_ratings: [],
    };
  },

  async mounted() {
    let response = await getTeam({
      year: constant.YEAR,
    });
    let teams = response.data.map((team) => team.id);
    this.getCodeforcesTable(teams);
    this.getCodeforcesChart(teams);
  },

  methods: {
    rank2color(rank) {
      const rank_color_mapping = {
        // todo
        // It is impossible for BUAA to have a LEGENDARY GRANDMASTER!!!
        // Actually it's because I'm too lazy to implement the first black letter...
        "legendary grandmaster": "",
        "international grandmaster": "user-red",
        grandmaster: "user-red",
        "international master": "user-orange",
        master: "user-orange",
        "candidate master": "user-violet",
        expert: "user-blue",
        specialist: "user-cyan",
        pupil: "user-green",
        newbie: "user-gray",
      };
      return rank_color_mapping[rank] || "user-black";
    },

    async getCodeforcesTable(teams) {
      let response = await statisticCodeforcesRatingDetail({
        team: teams,
      });
      // eslint-disable-next-line no-unused-vars
      this.ratings = Object.entries(response.data).sort(function ([_name1, score1], [_name2, score2]) {
        let diff = 0;
        score2.forEach((value) => (diff += value[2]));
        score1.forEach((value) => (diff -= value[2]));
        return diff;
      });
    },

    async getCodeforcesChart(teams) {
      let response = await statisticCodeforcesRating({
        team: teams,
        begin_time: constant.CODEFORCES_BEGIN,
        end_time: constant.CODEFORCES_END,
        least_compete: constant.CODEFORCES_LEAST_COMPETE,
      });
      this.chart_ratings = Object.entries(response.data);
    },
  },
};
</script>

<style scoped></style>
